// ---------------------------------------- sweatshirt css start ------------------------------------------
.front.sweatshirt.printer {
  position: absolute;
  top: 22%;
  left: 26%;
  width: 150px;
  height: 150px;
}

.back.sweatshirt.printer {
  position: absolute;
  top: 22%;
  left: 27%;
  width: 140px;
  height: 140px;
}

.left.sweatshirt.printer {
  position: absolute;
  top: 15%;
  left: 46%;
  width: 50px;
  height: 50px;
  transform: rotate(15deg);
}

.right.sweatshirt.printer {
  position: absolute;
  top: 15%;
  right: 46%;
  width: 50px;
  height: 50px;
  transform: rotate(15deg);
}

.left_front.sweatshirt.printer {
  position: absolute;
  top: 13%;
  right: 25%;
  width: 70px;
  height: 70px;
}

.rigth_front.sweatshirt.printer {
  position: absolute;
  top: 13%;
  left: 25%;
  width: 70px;
  height: 70px;
}

.inside_right_sleeve.sweatshirt.printer {
  position: absolute;
  top: 36%;
  left: 5%;
  width: 35px;
  height: 105px;
  transform: rotate(7deg);
}

.outside_right_sleeve.sweatshirt.printer {
  position: absolute;
  top: 36%;
  left: 5%;
  width: 35px;
  height: 105px;
  transform: rotate(7deg);
}

.outside_left_sleeve.sweatshirt.printer {
  position: absolute;
  top: 36%;
  right: 5%;
  width: 35px;
  height: 105px;
  transform: rotate(-7deg);
}

.inside_left_sleeve.sweatshirt.printer {
  position: absolute;
  top: 36%;
  right: 5%;
  width: 35px;
  height: 105px;
  transform: rotate(-7deg);
}

// ---------------------------------------- sweatshirt css end ------------------------------------------
// ---------------------------------------- T-shirt css start ------------------------------------------

.t-shirt.front.printer,
.comfort.front.printer {
  position: absolute;
  top: 26%;
  left: 30%;
  width: 130px;
  height: 130px;
}

.t-shirt.back.printer,
.comfort.back.printer {
  position: absolute;
  top: 25%;
  left: 28.5%;
  width: 130px;
  height: 130px;
}

.t-shirt.left.printer,
.comfort.left.printer {
  position: absolute;
  top: 15%;
  left: 44.5%;
  width: 60px;
  height: 60px;
}

.t-shirt.right.printer,
.comfort.right.printer {
  position: absolute;
  top: 15%;
  right: 43.5%;
  width: 60px;
  height: 60px;
}

.t-shirt.left_front.printer,
.comfort.left_front.printer {
  position: absolute;
  top: 15%;
  right: 27%;
  width: 85px;
  height: 85px;
}

.t-shirt.rigth_front.printer,
.comfort.rigth_front.printer {
  position: absolute;
  top: 15%;
  left: 27%;
  width: 85px;
  height: 85px;
}
// ---------------------------------------- T-shirt css end ------------------------------------------
// ---------------------------------------- Hoodie css start ------------------------------------------

.hoodie.front.printer {
  position: absolute;
  top: 14%;
  left: 29%;
  width: 130px;
  height: 130px;
}

.hoodie.back.printer {
  position: absolute;
  top: 23%;
  left: 29%;
  width: 130px;
  height: 130px;
}

.hoodie.left.printer {
  position: absolute;
  top: 12%;
  left: 40%;
  width: 60px;
  height: 60px;
}

.hoodie.right.printer {
  position: absolute;
  top: 12%;
  right: 40%;
  width: 60px;
  height: 60px;
}

.hoodie.left_front.printer {
  position: absolute;
  top: 14%;
  right: 27%;
  width: 85px;
  height: 85px;
}

.hoodie.rigth_front.printer {
  position: absolute;
  top: 14%;
  left: 27%;
  width: 85px;
  height: 85px;
}

// ---------------------------------------- Hoodie css end ------------------------------------------
// ---------------------------------------- Onesie css start ------------------------------------------
.onesie.front.printer {
  position: absolute;
  top: 21%;
  left: 29%;
  width: 130px;
  height: 130px;
}

.onesie.back.printer {
  position: absolute;
  top: 21%;
  left: 28.5%;
  width: 130px;
  height: 130px;
}

// ---------------------------------------- Onesie css end ------------------------------------------

.image_card_p0 {
  padding: 0 !important;
}

.image_card_p0 .ant-card-body {
  padding: 0 !important;
}
