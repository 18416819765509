//
// toastr.scss
//

/* =============
   Notification
// ============= */
#toast-container {
  > div {
    box-shadow: $box-shadow;
    opacity: 1;
    &:hover {
      box-shadow: $box-shadow;
      opacity: 0.9;
    }
  }
  &.toast-top-full-width,
  &.toast-bottom-full-width {
    > div {
      min-width: 100%;
    }
  }
 }

 @each $color, $value in $theme-colors {
   .toast-#{$color} {
     border: 2px solid $value !important;
     background-color: rgba(($value), 0.8) !important;
   }
 }
 .toast-success {
   border: 2px solid #03aa6d !important;
   background-color: #03aa6d !important;
 }
 .toast-error {
   background-color: #f46a6a !important;
   border: 2px px solid #f46a6a !important;
 }
 // for error 
 .toast-error {
   background-color: rgba($danger, 0.8);
   border: 2px solid $danger;
 } .toastr-options {
   padding: 24px;
   background-color: lighten($gray-200, 2%);
   margin-bottom: 0;
   border: 1px solid $border-color;
 }
