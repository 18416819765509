// ---------------------------------------- sweatshirt css start ------------------------------------------
.front.sweatshirt.showImage {
  position: absolute;
  top: 22%;
  left: 26%;
  width: 150px;
  height: 150px;
}

.back.sweatshirt.showImage {
  position: absolute;
  top: 22%;
  left: 27%;
  width: 140px;
  height: 140px;
}

.left.sweatshirt.showImage {
  position: absolute;
  top: 21%;
  left: 45%;
  width: 50px;
  height: 50px;
  transform: rotate(15deg);
}

.right.sweatshirt.showImage {
  position: absolute;
  top: 21%;
  right: 46.5%;
  width: 50px;
  height: 50px;
  transform: rotate(-15deg);
}

.left_front.sweatshirt.showImage {
  position: absolute;
  top: 21%;
  right: 25%;
  width: 60px;
  height: 60px;
}

.rigth_front.sweatshirt.showImage {
  position: absolute;
  top: 21%;
  left: 25%;
  width: 60px;
  height: 60px;
}

.inside_right_sleeve.sweatshirt.showImage {
  position: absolute;
  top: 50%;
  left: 5%;
  width: 35px;
  height: 105px;
  transform: rotate(7deg);
}

.outside_right_sleeve.sweatshirt.showImage {
  position: absolute;
  top: 50%;
  left: 5.5%;
  width: 35px;
  height: 105px;
  transform: rotate(7deg);
}

.outside_left_sleeve.sweatshirt.showImage {
  position: absolute;
  top: 50%;
  right: 5%;
  width: 35px;
  height: 105px;
  transform: rotate(-7deg);
}

.inside_left_sleeve.sweatshirt.showImage {
  position: absolute;
  top: 50%;
  right: 5%;
  width: 35px;
  height: 105px;
  transform: rotate(-7deg);
}

// ---------------------------------------- sweatshirt css end ------------------------------------------
// ---------------------------------------- T-shirt css start ------------------------------------------

.t-shirt.front.showImage,
.comfort.front.showImage {
  position: absolute;
  top: 26%;
  left: 29.5%;
  width: 130px;
  height: 130px;
}

.t-shirt.back.showImage,
.comfort.back.showImage {
  position: absolute;
  top: 25%;
  left: 29%;
  width: 130px;
  height: 130px;
}

.t-shirt.left.showImage,
.comfort.left.showImage {
  position: absolute;
  top: 20%;
  left: 44.5%;
  width: 60px;
  height: 60px;
}

.t-shirt.right.showImage,
.comfort.right.showImage {
  position: absolute;
  top: 20%;
  right: 43.5%;
  width: 60px;
  height: 60px;
}

.t-shirt.left_front.showImage,
.comfort.left_front.showImage {
  position: absolute;
  top: 19%;
  right: 27%;
  width: 85px;
  height: 85px;
}

.t-shirt.rigth_front.showImage,
.comfort.rigth_front.showImage {
  position: absolute;
  top: 19%;
  left: 27%;
  width: 85px;
  height: 85px;
}

// ---------------------------------------- T-shirt css end ------------------------------------------
// ---------------------------------------- Hoodie css start ------------------------------------------

.hoodie.showImage.front {
  position: absolute;
  top: 19%;
  left: 29%;
  width: 130px;
  height: 130px;
}

.hoodie.showImage.back {
  position: absolute;
  top: 29%;
  left: 29%;
  width: 130px;
  height: 130px;
}

.hoodie.showImage.left {
  position: absolute;
  top: 17%;
  left: 40%;
  width: 60px;
  height: 60px;
}

.hoodie.showImage.right {
  position: absolute;
  top: 17%;
  right: 40%;
  width: 60px;
  height: 60px;
}

.hoodie.showImage.left_front {
  position: absolute;
  top: 18%;
  right: 27%;
  width: 65px;
  height: 65px;
}

.hoodie.showImage.rigth_front {
  position: absolute;
  top: 18%;
  left: 27%;
  width: 65px;
  height: 65px;
}

// ---------------------------------------- Hoodie css end ------------------------------------------
// ---------------------------------------- Onesie css start ------------------------------------------
.onesie.showImage.front {
  position: absolute;
  top: 35%;
  left: 28.5%;
  width: 130px;
  height: 130px;
}

.onesie.showImage.back {
  position: absolute;
  top: 35%;
  left: 28.5%;
  width: 130px;
  height: 130px;
}

.custom_show_info {
  font-weight: 700;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.custom_show_info .ant-typography,
.custom_show_info .text-primary,
.custom_show_info .text-default {
  margin-left: 10px;
  opacity: 0.8;
}

.ant-input-outlined.ant-input-disabled,
.ant-input-outlined[disabled] {
  color: #00000096 !important;
}
.ant-descriptions-item-content {
  word-wrap: break-word;
  white-space: normal;
}
.whiteSpace-normal {
  white-space: nowrap !important;
}
.printer-list {
  list-style-type: none; /* Varsayılan liste stilini kaldırır */
  padding: 0;
  display: flex; /* Liste elemanlarını yan yana dizer */
  align-items: center;
  justify-content: space-around;
  margin-right: 10px;
}

.file-download-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  margin-bottom: 10px;
}

.file-download-table th,
.file-download-table td {
  padding: 12px 15px;
  border: 1px solid #e0e0e0;
}

.file-download-table thead {
  background-color: #f4f4f9;
}

.file-download-table th {
  font-weight: bold;
}

.file-download-table tr {
  transition: background-color 0.2s;
}

.file-download-table .even-row {
  background-color: #ffffff;
}

.file-download-table .odd-row {
  background-color: #f9f9f9;
}

.file-download-table tr:hover {
  background-color: #f1f1f1;
}

/* Responsive Design */
@media screen and (max-width: 600px) {
  .file-download-table th,
  .file-download-table td {
    font-size: 14px;
    padding: 10px;
  }
}
