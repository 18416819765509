//
// _buttons.scss
//

button,
a {
  outline: none !important;
}

// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

// example 1

.btn-group-example {
  .btn {
    position: relative;

    &:first-child {
      &::before {
        display: none;
      }
    }

    &:before {
      content: "OR";
      position: absolute;
      font-size: 10px;
      width: 24px;
      height: 24px;
      line-height: 24px;
      border-radius: 50%;
      background-color: $gray-600;
      color: $light;
      border: 1px solid $white;
      left: -12px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
  }
}

// Example 2

.btn-label {
  position: relative;
  padding-left: 44px;
  .label-icon {
    position: absolute;
    width: 32px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba($white, 0.1);
    border-right: 1px solid rgba($white, 0.1);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.btn-light {
    .label-icon {
      background-color: rgba($dark, 0.1);
      border-right: 1px solid rgba($dark, 0.2);
    }
  }
}

.btn-group-vertical {
  label {
    margin-bottom: 0px;
  }
}

.btn_default,
.btn_default.ant-btn-default.ant-btn-dashed {
  padding: 0 20px;
  color: #000000 !important;
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #ededed;
  --bs-btn-border-color: #ededed;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #ededed;
  --bs-btn-hover-border-color: #ededed;
  --bs-btn-focus-shadow-rgb: 111, 132, 234;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #ededed;
  --bs-btn-active-border-color: #ededed;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #ededed;
  --bs-btn-disabled-border-color: #ededed;
}

.btn_default:hover {
  color: #ffffff !important;
  border-color: rgba(255, 255, 255, 0) !important;
  background: #878787 !important;
  scale: 1.05;
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #878787;
  --bs-btn-border-color: #878787;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #878787;
  --bs-btn-hover-border-color: #878787;
  --bs-btn-focus-shadow-rgb: 111, 132, 234;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #878787;
  --bs-btn-active-border-color: #878787;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #878787;
  --bs-btn-disabled-border-color: #878787;
}

.btn_success,
.btn_success.ant-btn-default.ant-btn-dashed {
  padding: 0 20px;
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #3a9f65;
  --bs-btn-border-color: #3a9f65;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #3a9f65;
  --bs-btn-hover-border-color: #3a9f65;
  --bs-btn-focus-shadow-rgb: 111, 132, 234;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #3a9f65;
  --bs-btn-active-border-color: #3a9f65;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #3a9f65;
  --bs-btn-disabled-border-color: #3a9f65;
}

.btn_success:hover {
  color: #ffffff !important;
  border-color: rgba(255, 255, 255, 0) !important;
  background: #0a6430 !important;
  scale: 1.05;
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #0a6430;
  --bs-btn-border-color: #0a6430;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #0a6430;
  --bs-btn-hover-border-color: #0a6430;
  --bs-btn-focus-shadow-rgb: 111, 132, 234;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #0a6430;
  --bs-btn-active-border-color: #0a6430;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #0a6430;
  --bs-btn-disabled-border-color: #0a6430;
}

.btn_primary,
.btn_primary.ant-btn-default.ant-btn-dashed {
  padding: 0 20px;
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #3498db;
  --bs-btn-border-color: #3498db;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #3498db;
  --bs-btn-hover-border-color: #3498db;
  --bs-btn-focus-shadow-rgb: 111, 132, 234;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #3498db;
  --bs-btn-active-border-color: #3498db;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #3498db;
  --bs-btn-disabled-border-color: #3498db;
}
.btn_primary:hover {
  color: #ffffff !important;
  background: #135b8b !important;
  border-color: rgba(255, 255, 255, 0) !important;
  scale: 1.05;
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #135b8b;
  --bs-btn-border-color: #135b8b;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #135b8b;
  --bs-btn-hover-border-color: #135b8b;
  --bs-btn-focus-shadow-rgb: 111, 132, 234;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #135b8b;
  --bs-btn-active-border-color: #135b8b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #135b8b;
  --bs-btn-disabled-border-color: #135b8b;
}

.btn_secondary,
.btn_secondary.ant-btn-default.ant-btn-dashed {
  padding: 0 20px;
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #e67e22;
  --bs-btn-border-color: #e67e22;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #e67e22;
  --bs-btn-hover-border-color: #e67e22;
  --bs-btn-focus-shadow-rgb: 111, 132, 234;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #e67e22;
  --bs-btn-active-border-color: #e67e22;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #e67e22;
  --bs-btn-disabled-border-color: #e67e22;
}
.btn_secondary:hover {
  color: #ffffff !important;
  background: #8f490d !important;
  border-color: rgba(255, 255, 255, 0) !important;
  scale: 1.05;
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #8f490d;
  --bs-btn-border-color: #8f490d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #8f490d;
  --bs-btn-hover-border-color: #8f490d;
  --bs-btn-focus-shadow-rgb: 111, 132, 234;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #8f490d;
  --bs-btn-active-border-color: #8f490d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #8f490d;
  --bs-btn-disabled-border-color: #8f490d;
}

.btn_danger,
.btn_danger.ant-btn-default.ant-btn-dashed {
  padding: 0 20px;
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #f94040;
  --bs-btn-border-color: #f94040;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #f94040;
  --bs-btn-hover-border-color: #f94040;
  --bs-btn-focus-shadow-rgb: 111, 132, 234;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #f94040;
  --bs-btn-active-border-color: #f94040;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #f94040;
  --bs-btn-disabled-border-color: #f94040;
}
.btn_danger:hover {
  color: #ffffff !important;
  background: #9d1111 !important;
  border-color: rgba(255, 255, 255, 0) !important;
  scale: 1.05;
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #9d1111;
  --bs-btn-border-color: #9d1111;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #9d1111;
  --bs-btn-hover-border-color: #9d1111;
  --bs-btn-focus-shadow-rgb: 111, 132, 234;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #9d1111;
  --bs-btn-active-border-color: #9d1111;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #9d1111;
  --bs-btn-disabled-border-color: #9d1111;
}

.btn_tertiary,
.btn_tertiary.ant-btn-default.ant-btn-dashed {
  padding: 0 20px;
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #34495e;
  --bs-btn-border-color: #34495e;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #34495e;
  --bs-btn-hover-border-color: #34495e;
  --bs-btn-focus-shadow-rgb: 111, 132, 234;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #34495e;
  --bs-btn-active-border-color: #34495e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #34495e;
  --bs-btn-disabled-border-color: #34495e;
}

.btn_tertiary:hover {
  color: #ffffff !important;
  border-color: rgba(255, 255, 255, 0) !important;
  background: #0d2135 !important;
  scale: 1.05;
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #0d2135;
  --bs-btn-border-color: #0d2135;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #0d2135;
  --bs-btn-hover-border-color: #0d2135;
  --bs-btn-focus-shadow-rgb: 111, 132, 234;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #0d2135;
  --bs-btn-active-border-color: #0d2135;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #0d2135;
  --bs-btn-disabled-border-color: #0d2135;
}

.btn_quaternary,
.btn_quaternary.ant-btn-default.ant-btn-dashed {
  padding: 0 20px;
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #9b59b6;
  --bs-btn-border-color: #9b59b6;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #9b59b6;
  --bs-btn-hover-border-color: #9b59b6;
  --bs-btn-focus-shadow-rgb: 111, 132, 234;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #9b59b6;
  --bs-btn-active-border-color: #9b59b6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #9b59b6;
  --bs-btn-disabled-border-color: #9b59b6;
}

.btn_quaternary:hover {
  color: #ffffff !important;
  border-color: rgba(255, 255, 255, 0) !important;
  background: #7500a3 !important;
  scale: 1.05;
  --bs-btn-bg: #7500a3;
  --bs-btn-border-color: #7500a3;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #7500a3;
  --bs-btn-hover-border-color: #7500a3;
  --bs-btn-focus-shadow-rgb: 111, 132, 234;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #7500a3;
  --bs-btn-active-border-color: #7500a3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #7500a3;
  --bs-btn-disabled-border-color: #7500a3;
}

.btn_quinary,
.btn_quinary.ant-btn-default.ant-btn-dashed {
  padding: 0 20px;
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #e74c3c;
  --bs-btn-border-color: #e74c3c;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #e74c3c;
  --bs-btn-hover-border-color: #e74c3c;
  --bs-btn-focus-shadow-rgb: 111, 132, 234;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #e74c3c;
  --bs-btn-active-border-color: #e74c3c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #e74c3c;
  --bs-btn-disabled-border-color: #e74c3c;
}

.btn_quinary:hover {
  color: #ffffff !important;
  border-color: rgba(255, 255, 255, 0) !important;
  background: #861f13 !important;
  scale: 1.05;
  --bs-btn-bg: #861f13;
  --bs-btn-border-color: #861f13;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #861f13;
  --bs-btn-hover-border-color: #861f13;
  --bs-btn-focus-shadow-rgb: 111, 132, 234;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #861f13;
  --bs-btn-active-border-color: #7500a3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #861f13;
  --bs-btn-disabled-border-color: #861f13;
}

.btn-primary:hover {
  color: #ffffff !important;
  border-color: rgba(255, 255, 255, 0) !important;
  background: #2c4ad9 !important;
  scale: 1.01;
  --bs-btn-bg: #2c4ad9;
  --bs-btn-border-color: #2c4ad9;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #2c4ad9;
  --bs-btn-hover-border-color: #2c4ad9;
  --bs-btn-focus-shadow-rgb: 111, 132, 234;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #2c4ad9;
  --bs-btn-active-border-color: #2c4ad9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #2c4ad9;
  --bs-btn-disabled-border-color: #2c4ad9;
}

.localLable {
  color: black !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
}

.localLable .ant-tag-close-icon {
  color: black !important;
  margin-block-start: 3px !important;
}

.localLable .ant-tag-close-icon:hover {
  scale: 1.15;
}

.btn_disabled {
  padding: 0 20px;
  opacity: 0.3 !important;
  background: #b5b5b5 !important;
  --bs-btn-color: #000000;
  --bs-btn-bg: #b5b5b5;
  --bs-btn-border-color: #b5b5b5;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #b5b5b5;
  --bs-btn-hover-border-color: #b5b5b5;
  --bs-btn-focus-shadow-rgb: 111, 132, 234;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #b5b5b5;
  --bs-btn-active-border-color: #b5b5b5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #b5b5b5;
  --bs-btn-disabled-border-color: #b5b5b5;
}

.pdf_download:hover {
  scale: 1.1;
}

.btn_disabled:hover {
  color: #000000 !important;
  cursor: default;
  background: #b5b5b5 !important;
  border-color: rgba(255, 255, 255, 0) !important;
  scale: 1 !important;
  --bs-btn-bg: #b5b5b5;
  --bs-btn-border-color: #b5b5b5;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #b5b5b5;
  --bs-btn-hover-border-color: #b5b5b5;
  --bs-btn-focus-shadow-rgb: 111, 132, 234;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #b5b5b5;
  --bs-btn-active-border-color: #b5b5b5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #b5b5b5;
  --bs-btn-disabled-border-color: #b5b5b5;
}

.ant-modal .ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 20px;
  line-height: 1.5;
  word-wrap: break-word;
  margin: 0 0 20px 0 !important;
  font-weight: 700 !important;
}
//
// Soft Buttons
//

@mixin button-variant-soft($bg) {
  color: $bg;
  background-color: rgba($bg, 0.1);
  border-color: transparent;
  transition: all 0.5s ease;
  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: $bg;
    border-color: transparent;
  }
}

@each $color, $value in $theme-colors {
  .btn-soft-#{$color} {
    @include button-variant-soft($value);
  }
}

.like-btn {
  &.active {
    .bx-heart {
      color: $danger;
      &:before {
        content: "\ec8f";
      }
    }
  }
}
