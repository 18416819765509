// ---------------------------------------- sweatshirt css start ------------------------------------------
.front.sweatshirt.designApparel {
  position: absolute;
  top: 25%;
  left: 29%;
  width: 150px;
  height: 150px;
}

.back.sweatshirt.designApparel {
  position: absolute;
  top: 25%;
  left: 30%;
  width: 140px;
  height: 140px;
}

.left.sweatshirt.designApparel {
  position: absolute;
  top: 19%;
  left: 48%;
  width: 50px;
  height: 50px;
  transform: rotate(15deg);
}

.right.sweatshirt.designApparel {
  position: absolute;
  top: 19%;
  right: 48%;
  width: 50px;
  height: 50px;
  transform: rotate(15deg);
}

.left_front.sweatshirt.designApparel {
  position: absolute;
  top: 21%;
  right: 25%;
  width: 60px;
  height: 60px;
}

.rigth_front.sweatshirt.designApparel {
  position: absolute;
  top: 21%;
  left: 25%;
  width: 60px;
  height: 60px;
}

.inside_right_sleeve.sweatshirt.designApparel {
  position: absolute;
  top: 50%;
  left: 5.5%;
  width: 35px;
  height: 105px;
  transform: rotate(7deg);
}

.outside_right_sleeve.sweatshirt.designApparel {
  position: absolute;
  top: 50%;
  left: 6%;
  width: 35px;
  height: 105px;
  transform: rotate(7deg);
}

.outside_left_sleeve.sweatshirt.designApparel {
  position: absolute;
  top: 50%;
  right: 5%;
  width: 35px;
  height: 105px;
  transform: rotate(-7deg);
}

.inside_left_sleeve.sweatshirt.designApparel {
  position: absolute;
  top: 50%;
  right: 5%;
  width: 35px;
  height: 105px;
  transform: rotate(-7deg);
}

// ---------------------------------------- sweatshirt css end ------------------------------------------
// ---------------------------------------- T-shirt css start ------------------------------------------

.t-shirt.front.designApparel,
.comfort.front.designApparel {
  position: absolute;
  top: 31%;
  left: 31.5%;
  width: 130px;
  height: 130px;
}

.t-shirt.back.designApparel,
.comfort.back.designApparel {
  position: absolute;
  top: 29%;
  left: 31.5%;
  width: 130px;
  height: 130px;
}

.t-shirt.left.designApparel,
.comfort.left.designApparel {
  position: absolute;
  top: 22%;
  left: 46%;
  width: 60px;
  height: 60px;
}

.t-shirt.right.designApparel,
.comfort.right.designApparel {
  position: absolute;
  top: 22%;
  right: 45%;
  width: 60px;
  height: 60px;
}

.t-shirt.left_front.designApparel,
.comfort.left_front.designApparel {
  position: absolute;
  top: 19%;
  right: 27%;
  width: 85px;
  height: 85px;
}

.t-shirt.rigth_front.designApparel,
.comfort.rigth_front.designApparel {
  position: absolute;
  top: 19%;
  left: 27%;
  width: 85px;
  height: 85px;
}
// ---------------------------------------- T-shirt css end ------------------------------------------
// ---------------------------------------- Hoodie css start ------------------------------------------

.hoodie.designApparel.front {
  position: absolute;
  top: 19%;
  left: 29%;
  width: 130px;
  height: 130px;
}

.hoodie.designApparel.back {
  position: absolute;
  top: 29%;
  left: 29%;
  width: 130px;
  height: 130px;
}

.hoodie.designApparel.left {
  position: absolute;
  top: 17%;
  left: 40%;
  width: 60px;
  height: 60px;
}

.hoodie.designApparel.right {
  position: absolute;
  top: 17%;
  right: 40%;
  width: 60px;
  height: 60px;
}

.hoodie.designApparel.left_front {
  position: absolute;
  top: 18%;
  right: 27%;
  width: 65px;
  height: 65px;
}

.hoodie.designApparel.rigth_front {
  position: absolute;
  top: 18%;
  left: 27%;
  width: 65px;
  height: 65px;
}

// ---------------------------------------- Hoodie css end ------------------------------------------
// ---------------------------------------- Onesie css start ------------------------------------------
.onesie.designApparel.front {
  position: absolute;
  top: 35%;
  left: 32%;
  width: 130px;
  height: 130px;
}

.onesie.designApparel.back {
  position: absolute;
  top: 35%;
  left: 32%;
  width: 130px;
  height: 130px;
}

// ---------------------------------------- Onesie css end ------------------------------------------
